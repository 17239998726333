import _export from "../internals/export";
import _arrayFrom from "../internals/array-from";
import _checkCorrectnessOfIteration from "../internals/check-correctness-of-iteration";
var $ = _export;
var from = _arrayFrom;
var checkCorrectnessOfIteration = _checkCorrectnessOfIteration;
var INCORRECT_ITERATION = !checkCorrectnessOfIteration(function (iterable) {
  // eslint-disable-next-line es/no-array-from -- required for testing
  Array.from(iterable);
});

// `Array.from` method
// https://tc39.es/ecma262/#sec-array.from
$({
  target: "Array",
  stat: true,
  forced: INCORRECT_ITERATION
}, {
  from: from
});
export default {};